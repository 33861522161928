<!-- QuillEditor.vue -->
<template>
  <div>
    <quill-editor v-model="content" :options="editorOptions"></quill-editor>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      content: '',
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
        },
        placeholder: 'Write something...',
        theme: 'snow',
      },
    };
  },
};
</script>

<style scoped>
/* Add any custom styling here */
</style>
