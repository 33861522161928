<template>
  <div>
    <form @submit.prevent="createCarousel" class="mt-4">
      <div class="row">
        <div class="col-6 mb-3">
          <label for="name" class="form-label"> Titre :</label>
          <input
            type="text"
            id="name"
            v-model="carousel.title"
            class="form-control"
            required
          />
        </div>

        <div class="col-3 mb-3">
          <label for="imageFr" class="form-label">Image Francais</label>
          <input
            type="file"
            id="imageFr"
            @change="handleImageFrenchUpload"
            class="form-control"
            accept="image/*"
          />
        </div>

        <div class="col-3 mb-3">
          <label for="imageAr" class="form-label">Image Arabe</label>
          <input
            type="file"
            id="imageAr"
            @change="handleImageArabicUpload"
            class="form-control"
            accept="image/*"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-6 mb-3 p-2">
          <img
            :src="carousel.imageUrl"
            v-if="carousel.image"
            alt=""
            class="w-100 shadow rounded card"
          />
        </div>

        <div class="col-6 mb-3 p-2">
          <img
            :src="carousel.imageUrlAr"
            v-if="carousel.imageAr"
            alt=""
            class="w-100 shadow rounded card"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mb-3">
          <button type="submit" class="btn btn-primary">
            Enregistrer la carousele
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import QuillEditorVue from "../../components/cards/QuillEditor.vue";
export default {
  compoenents: {
    QuillEditorVue,
  },
  data() {
    return {
      carousel: {
        title: "",
        image: null,
        imageAr: null,
        imageUrl: null,
        imageUrlAr: null,
      },
    };
  },
  methods: {
    createCarousel() {
      const formData = new FormData();
      formData.append("title", this.carousel.title);
      formData.append("image", this.carousel.image);
      formData.append("imageAr", this.carousel.imageAr);
      this.$store.dispatch("carousel/create", formData);
    },

    handleImageFrenchUpload(event) {
      const file = event.target.files[0];
      this.carousel.image = file;
      this.carousel.imageUrl = URL.createObjectURL(file);
    },

    handleImageArabicUpload(event) {
      const file = event.target.files[0];
      this.carousel.imageAr = file;
      this.carousel.imageUrlAr = URL.createObjectURL(file);
    },
  },
};
</script>
